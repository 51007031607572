@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "dm-sans";
  src: url("./fonts/DMSans-Bold.ttf");
  font-weight: bold;
  font-style: bold;
}

@font-face {
  font-family: "dm-sans";
  src: url("./fonts/DMSans-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "dm-sans";
  src: url("./fonts/DMSans-Regular.ttf");
  font-weight: 300;
  font-style: normal;
}

.loading-spinner {
  border-top-color: #fd5c5e;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* .ant-input,
.ant-input:hover,
.ant-input-password,
.ant-input-password:hover {
  border-color: #2b2b2b11 !important;
}

.ant-input:focus,
.ant-input-focused {
  border-color: #2b2b2b11 !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px #f2f2f288;
  box-shadow: 0 0 0 2px #f2f2f288;
}

.ant-input-password > input {
  background-color: #f2f2f2;
} */

.ant-spin-dot-item {
  background-color: #fd5c5e;
}

.ant-modal-wrap {
  display: flex;
  align-items: center;
}

.ant-modal {
  top: 0px;
}

.ant-modal-title {
  font-family: dm-sans;
  font-weight: bold;
  font-size: 20px;
}

.ant-checkbox-group-item {
  display: block;
  margin-right: 0;
}

.ant-checkbox-group-item span {
  font-family: dm-sans;
  font-family: 300;
  color: #666;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

.ant-tabs-tab {
  font-family: dm-sans !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #666 !important;
}

.ant-tabs-tab-active {
  color: #973f3f !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fd5c5e !important;
}

.ant-tabs-ink-bar {
  border-bottom: 2px solid #fd5c5e !important;
}
